import React from 'react';
import { WorkloadStaffType, WorkplanVersion } from '@/wpt-lib/interfaces/Workplan';
import { CategoryRating } from '@/wpt-lib/pdf/review/Rating';

const shouldDisplayCategoryRatings = (version: WorkplanVersion) => {
    const { teachingEndOfYearRating, researchEndOfYearRating, serviceEndOfYearRating, staffType } = version;

    if (staffType === WorkloadStaffType.SENIOR) {
        return teachingEndOfYearRating || researchEndOfYearRating || serviceEndOfYearRating;
    }

    return staffType === WorkloadStaffType.ACADEMIC;
};

export const RatingsDisplay = ({
    version,
    hasHighlyExceeding,
}: {
    version: WorkplanVersion;
    hasHighlyExceeding: boolean;
}) => {
    if (!shouldDisplayCategoryRatings(version)) {
        return null; // Don't display anything for PROFESSIONAL staff or SENIOR without academic ratings
    }

    return (
        <>
            <CategoryRating
                label="Teaching"
                rating={version.teachingEndOfYearRating ? version.teachingEndOfYearRating : undefined}
                hasHighlyExceeding={hasHighlyExceeding}
            />
            <CategoryRating
                label="Research"
                rating={version.researchEndOfYearRating ? version.researchEndOfYearRating : undefined}
                hasHighlyExceeding={hasHighlyExceeding}
            />
            <CategoryRating
                label="Engagement"
                rating={version.serviceEndOfYearRating ? version.serviceEndOfYearRating : undefined}
                hasHighlyExceeding={hasHighlyExceeding}
            />
        </>
    );
};
