import { Button, Stack, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { SimpleAlert } from '../SimpleAlert/SimpleAlert';
import { SingleEOYRating } from './SingleEOYRating';
import { SingleProbationReview } from './SingleProbationReview';
import { formatName } from '@/wpt-lib/utils/formatName';
import {
    UpdateWorkplanEOYRatingDto,
    WorkloadStaffType,
    Workplan,
    WorkplanState,
    WorkplanVersion,
} from '@/wpt-lib/interfaces/Workplan';
import { usePutRequest } from '@/utils/useStatefulRequests';
import useWorkplanVersion from '@/components/contexts/VersionContext/useWorkplanVersion';
import { useAssignment } from '@/components/contexts/AssignmentContext/useAssignment';
import { isNullish } from '@/wpt-lib/utils/isNullish';
import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';

export interface WorkplanEOYRatingProps {
    workplan: Workplan;
    workplanVersion: WorkplanVersion;
}

const academicRatingFields: (keyof WorkplanVersion)[] = [
    'teachingEndOfYearRating',
    'researchEndOfYearRating',
    'serviceEndOfYearRating',
];

const RatingsFields = (workplanVersion: WorkplanVersion): (keyof WorkplanVersion)[] => {
    const ratingFields: (keyof WorkplanVersion)[] = [];

    if (workplanVersion.staffType === WorkloadStaffType.ACADEMIC) {
        ratingFields.push(...academicRatingFields);
    } else if (
        workplanVersion.staffType === WorkloadStaffType.SENIOR &&
        workplanVersion.teachingWorkloadPortion +
            workplanVersion.researchWorkloadPortion +
            workplanVersion.serviceWorkloadPortion >
            0
    ) {
        ratingFields.push(...academicRatingFields);
    }

    ratingFields.push('overallEndOfYearRating');
    if (workplanVersion.probationPlan) {
        ratingFields.push('isFinalProbation');
    }
    return ratingFields;
};

const RatingTitles: Record<string, string> = {
    teachingEndOfYearRating: 'Teaching & Educational Facilitation',
    researchEndOfYearRating: 'Research & Scholarship',
    serviceEndOfYearRating: 'Service & Engagement',
    overallEndOfYearRating: 'Overall',
    isFinalProbation: 'Is this the final probation review?',
};

export function isEOYRatingSet(workplanVersion?: WorkplanVersion): boolean {
    return !!workplanVersion && !RatingsFields(workplanVersion).some((f) => isNullish(workplanVersion[f]));
}

export const WorkplanEOYRating = ({ workplan, workplanVersion }: WorkplanEOYRatingProps) => {
    const { currentAssignment } = useAssignment();

    const { updateVersion, current } = useWorkplanVersion();
    const { supervisor, admin } = useNavigateOptions();

    const readonly = !(
        current &&
        (supervisor || admin) &&
        [WorkplanState.REVIEW, WorkplanState.MODERATION].includes(workplan.state)
    );

    const { loading, error, makeRequest: updateEOYRating } = usePutRequest<unknown, UpdateWorkplanEOYRatingDto>();

    const [saveStatus, setSaveStatus] = useState<boolean>(false);
    const handleSave = async () => {
        if (!currentAssignment) return;

        try {
            await updateEOYRating(
                `workplan/${workplan.id}`,
                {
                    endOfYearRating: Object.fromEntries(
                        RatingsFields(workplanVersion)
                            .map((f) => [f, workplanVersion[f]])
                            .filter((e) => e[1] !== 0),
                    ),
                } as UpdateWorkplanEOYRatingDto,
                {
                    params: {
                        assignment: currentAssignment.id,
                    },
                },
            );

            setSaveStatus(true);
        } catch {
            /// handled by hook
        }
    };

    const hasHighExceeding =
        Math.max(
            ...RatingsFields(workplanVersion).map((f) =>
                Number.isInteger(workplanVersion[f]) ? Number(workplanVersion[f]) : 0,
            ),
        ) === 5;

    const handleChange = (rating: string, value: number | boolean) => {
        updateVersion({ [rating]: value });
        setSaveStatus(false);
    };

    return (
        <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
            <Text fontSize={'xl'}>
                {!readonly
                    ? `Please select the end of year rating for ${formatName(workplan?.assignment?.user)}`
                    : 'End Of Year Rating'}
            </Text>
            {RatingsFields(workplanVersion).map((rating) =>
                rating === 'isFinalProbation' ? (
                    <SingleProbationReview
                        key={rating}
                        rating={rating}
                        title={RatingTitles[rating]}
                        value={workplanVersion[rating]}
                        handleChange={handleChange}
                        readOnly={readonly}
                    />
                ) : (
                    <SingleEOYRating
                        key={rating}
                        rating={rating}
                        title={RatingTitles[rating]}
                        value={(workplanVersion[rating] ?? 0) as number}
                        handleChange={handleChange}
                        readOnly={readonly}
                        hasHighExceeding={hasHighExceeding}
                    />
                ),
            )}
            <VStack alignSelf={'center'} position={'relative'} justifyContent={'center'}>
                {!readonly && (
                    <Button colorScheme="gray" variant="solid" isLoading={loading} onClick={handleSave} flexShrink={0}>
                        Save End Of Year Rating
                    </Button>
                )}
                {saveStatus && (
                    <Text position={'absolute'} left={225} style={{ margin: 0 }}>
                        Saved!
                    </Text>
                )}
            </VStack>
            {!!error && (
                <SimpleAlert width={'100%'} variant="subtle">
                    Could not save ratings, please try again.
                </SimpleAlert>
            )}
            {!isEOYRatingSet(workplanVersion) && (
                <SimpleAlert variant={'subtle'}>
                    You have to give end of year rating for this employee before endorsing workplan.
                </SimpleAlert>
            )}
        </Stack>
    );
};
