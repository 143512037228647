import React, { useRef, useState } from 'react';
import { useWorkplan } from '@/components/contexts/WorkplanContext/useWorkplan';
import useWorkplanVersion from '@/components/contexts/VersionContext/useWorkplanVersion';
import { isWorkplanInState } from '@/utils/isWorkplanReadonly';
import { Button } from '@chakra-ui/react';
import { WorkplanState } from '@/wpt-lib/interfaces/Workplan';
import { Dialog } from '@/components/Dialog';
import {
    withCurrentAssignment,
    WithCurrentAssignmentProps,
} from '@/components/contexts/AssignmentContext/withCurrentAssignment';
import WorkloadEditorForm, { WorkloadEditorFormRef } from '@/components/pages/Admin/Workload/WorkloadEditorForm';

const WorkplanUpdate = ({ currentAssignment }: WithCurrentAssignmentProps) => {
    const { workplan } = useWorkplan();
    const { workplanVersion, current, updateVersion } = useWorkplanVersion();

    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const ref = useRef<WorkloadEditorFormRef>(null);

    return workplan &&
        workplan.assignment &&
        workplanVersion &&
        current &&
        !isWorkplanInState(workplan, WorkplanState.ENDORSED, WorkplanState.HISTORICAL) ? (
        <>
            <Button h={12} whiteSpace="normal" colorScheme="blue" variant="solid" onClick={() => setOpen(true)}>
                Update Workload Allocation
            </Button>
            <Dialog
                dialogHeader="Update Workload Allocation"
                isOpen={open}
                isLoading={submitting}
                dialogAction={async () => {
                    if (!ref.current) return;

                    const values = ref.current.getValues();

                    setSubmitting(true);
                    setOpen(!(await ref.current.submit()));

                    if (values) {
                        updateVersion({
                            teachingWorkloadPortion: values.teaching,
                            researchWorkloadPortion: values.research,
                            serviceWorkloadPortion: values.service,
                            professionalStaffWorkloadPortion: values.professional,
                            seniorStaffWorkloadPortion: values.senior,
                            staffType: values.staffType,
                        });
                    }
                    setSubmitting(false);
                }}
                onClose={() => setOpen(false)}
            >
                <WorkloadEditorForm
                    workload={workplanVersion}
                    currentAssignment={currentAssignment}
                    saveButton={false}
                    ref={ref}
                    saveUrl={`workplan/${workplan.id}`}
                />
            </Dialog>
        </>
    ) : null;
};

export default withCurrentAssignment(WorkplanUpdate);
