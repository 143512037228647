import React, { useState } from 'react';
import { WorkplanStage, workplanStageHelpText, WorkplanState, WorkplanVersion } from '@/wpt-lib/interfaces/Workplan';
import { Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';

const Options: {
    [key in WorkplanState]?: { [key in WorkplanStage]?: [WorkplanState, WorkplanStage | undefined, string, string][] };
} = {
    [WorkplanState.SUBMITTED]: {
        [WorkplanStage.PLANNING]: [
            [WorkplanState.IN_PROGRESS, WorkplanStage.PART_YEAR, 'Part year stage', workplanStageHelpText.PART_YEAR],
            [WorkplanState.IN_PROGRESS, WorkplanStage.END_YEAR, 'End year stage', workplanStageHelpText.END_YEAR],
        ],
    },
    [WorkplanState.REVIEW]: {
        [WorkplanStage.PART_YEAR]: [
            [WorkplanState.IN_PROGRESS, WorkplanStage.PART_YEAR, 'Part year stage', workplanStageHelpText.PART_YEAR],
            [WorkplanState.IN_PROGRESS, WorkplanStage.END_YEAR, 'End year stage', workplanStageHelpText.END_YEAR],
        ],
        [WorkplanStage.END_YEAR]: [
            [WorkplanState.MODERATION, WorkplanStage.END_YEAR, 'Moderation', workplanStageHelpText.MODERATION],
            [WorkplanState.ENDORSED, undefined, 'Endorsed', workplanStageHelpText.ENDORSED],
        ],
    },
    [WorkplanState.MODERATION]: {
        [WorkplanStage.END_YEAR]: [[WorkplanState.ENDORSED, undefined, 'Endorsed', workplanStageHelpText.END_YEAR]],
    },
};

const SupervisorProgressOptions = ({
    workplanVersion,
    onSelect,
}: {
    workplanVersion?: WorkplanVersion;
    onSelect: (selected: [WorkplanState, WorkplanStage]) => void;
}) => {
    const options = workplanVersion
        ? Options[workplanVersion.state]?.[workplanVersion.stage]
        : [
              ...(Options[WorkplanState.REVIEW]?.[WorkplanStage.PART_YEAR] ?? []),
              ...(Options[WorkplanState.REVIEW]?.[WorkplanStage.END_YEAR] ?? []),
          ];
    const [selected, setSelected] = useState<number | undefined>(undefined);

    const onChange = (value: string) => {
        if (!options) return;

        setSelected(+value);
        onSelect(options[+value].slice(0, 2) as [WorkplanState, WorkplanStage]);
    };

    return (
        <>
            {options && (
                <RadioGroup value={selected} onChange={onChange}>
                    <Stack direction="column">
                        {options.map(([, , label, helpText], i) => (
                            <Radio key={i} size="md" cursor="pointer" value={i} aria-describedby={`help-text-${i}`}>
                                <Text as="span" fontWeight="bold">
                                    {label}
                                </Text>{' '}
                                -{' '}
                                {helpText && (
                                    <Text as="span" id={`help-text-${i}`} fontSize="sm">
                                        {helpText}
                                    </Text>
                                )}
                            </Radio>
                        ))}
                    </Stack>
                </RadioGroup>
            )}
        </>
    );
};

export default SupervisorProgressOptions;
