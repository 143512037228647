import { Dialog, DialogProps } from '@/components/Dialog';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import {
    UpdateWorkplanEOYRatingDto,
    UpdateWorkplanStateDto,
    Workplan,
    WorkplanStage,
    WorkplanState,
    WorkplanVersion,
} from '@/wpt-lib/interfaces/Workplan';
import usePageTitle from '@/utils/hooks/usePageTitle';
import { useGetRequest, usePutRequest } from '@/utils/useStatefulRequests';
import { Button, Flex, GridItem, HStack, Spinner, Stack, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useNavigateOptions from '@/components/contexts/NaviateContext/useNavigateOptions';
import { goToNextVersion } from '@/components/pages/dashboard/tiles/ButtonTile/SubmitForReviewButtonTile';
import { formatName } from '@/wpt-lib/utils/formatName';
import { isEOYRatingSet } from '@/components/WorkplanEOYRating/WorkplanEOYRating';
import SupervisorProgressOptions from '@/components/pages/dashboard/tiles/ButtonTile/SupervisorProgressOptions';
import WorkplanVersionStateDisplay from '@/components/contexts/VersionContext/WorkplanVersionStateDisplay';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import { WorkplanComment, WorkplanCommentSection } from '@/wpt-lib/interfaces/WorkplanComment';

export interface SupervisorActionButtonTileProps {
    workplan: Workplan;
    workplanVersion: WorkplanVersion;
    currentAssignment: Assignment;
}

enum DialogType {
    APPROVE = 1,
    REQUEST_INFORMATION = 2,
    UNENDORSE = 3,
}

const SupervisorActionButtonTile = ({
    workplan,
    workplanVersion,
    currentAssignment,
}: SupervisorActionButtonTileProps) => {
    const navigate = useNavigate();
    const { admin } = useNavigateOptions();

    usePageTitle(
        `${formatName(workplan.assignment?.user)} 
        ${workplan.year} Submitted Workplan `,
    );

    const {
        loading: isSubmittingWorkplan,
        error: submittingError,
        makeRequest: submitWorkplan,
    } = usePutRequest<unknown, UpdateWorkplanStateDto | UpdateWorkplanEOYRatingDto>();

    const {
        responseData: eoyComments,
        makeRequest: eoyCommentRequest,
        loading: eoyCommentsLoading,
    } = useGetRequest<WorkplanComment[]>();

    const [dialogType, setDialogType] = useState<DialogType | undefined>();
    const [nextStateStage, setNextStateStage] = useState<[WorkplanState, WorkplanStage] | undefined>(undefined);

    const allEOYRatingGiven = isEOYRatingSet(workplanVersion);

    useEffect(() => {
        eoyCommentRequest(`/workplan/${workplan.id}/comment`, {
            params: {
                section: WorkplanCommentSection.END_YEAR,
                version: workplanVersion.version,
                assignment: currentAssignment.id,
            },
        });
    }, [dialogType]);

    let dialogProps: Omit<DialogProps, 'isLoading' | 'onClose'>;
    switch (dialogType) {
        case DialogType.APPROVE:
            dialogProps = {
                isOpen: true,
                dialogHeader: 'Approve and Progress Workplan',
                dialogConfirmLabel: 'Confirm',
                dialogConfirmDisabled:
                    !nextStateStage ||
                    (nextStateStage[0] === WorkplanState.ENDORSED &&
                        (!allEOYRatingGiven || eoyCommentsLoading || eoyComments?.length === 0)),
                dialogBody: 'Please choose the stage of the workplan which you would like to move to:',
                colorScheme: 'blue',
                dialogAction: () => {
                    if (nextStateStage) changeWorkplanState(...nextStateStage);
                },
                children: (
                    <>
                        <SupervisorProgressOptions workplanVersion={workplanVersion} onSelect={setNextStateStage} />
                        {nextStateStage?.[0] === WorkplanState.ENDORSED && !allEOYRatingGiven && (
                            <SimpleAlert variant={'subtle'}>
                                You have to give end of year rating for this employee before endorsing workplan.
                            </SimpleAlert>
                        )}
                        {nextStateStage?.[0] === WorkplanState.ENDORSED &&
                            !eoyCommentsLoading &&
                            eoyComments?.length === 0 && (
                                <SimpleAlert variant={'subtle'}>
                                    You must add an end of year comment for this employee before endorsing workplan.
                                </SimpleAlert>
                            )}
                        {nextStateStage?.[0] === WorkplanState.ENDORSED && eoyCommentsLoading && (
                            <Spinner sx={{ position: 'absolute', right: '20px', bottom: '60px' }} />
                        )}
                    </>
                ),
            };
            break;

        case DialogType.REQUEST_INFORMATION:
            dialogProps = {
                isOpen: true,
                dialogHeader: 'Requesting more information for this workplan?',
                dialogConfirmLabel: 'Request more information',
                dialogBody: 'The author of the workplan will be notified to update the information',
                colorScheme: 'red',
                dialogAction: () =>
                    changeWorkplanState(
                        workplan.state === WorkplanState.SUBMITTED
                            ? WorkplanState.DRAFT
                            : WorkplanState.INFORMATION_REQUESTED,
                    ),
            };
            break;

        case DialogType.UNENDORSE:
            dialogProps = {
                isOpen: true,
                dialogHeader: 'Unendorse this workplan?',
                dialogConfirmLabel: 'Unendorse',
                dialogBody:
                    'Unendorsing this workplan allows the rating to be changed and further comments to be added.',
                colorScheme: 'red',
                dialogAction: () => changeWorkplanState(WorkplanState.REVIEW),
                children: undefined,
            };
            break;

        default:
            dialogProps = {
                isOpen: false,
                dialogHeader: '',
                dialogConfirmLabel: '',
                dialogBody: '',
                colorScheme: 'blue',
            };
    }

    const changeWorkplanState = async (state: WorkplanState, stage?: WorkplanStage) => {
        try {
            const data = {
                state,
                stage,
            };

            if (state === WorkplanState.ENDORSED && allEOYRatingGiven) {
                Object.assign(data, {
                    endOfYearRating: {
                        teachingEndOfYearRating: workplanVersion.teachingEndOfYearRating,
                        researchEndOfYearRating: workplanVersion.researchEndOfYearRating,
                        serviceEndOfYearRating: workplanVersion.serviceEndOfYearRating,
                        overallEndOfYearRating: workplanVersion.overallEndOfYearRating,
                        isFinalProbation: workplanVersion.isFinalProbation,
                    },
                });
            }
            await submitWorkplan(`workplan/${workplan.id}`, data, {
                params: {
                    assignment: currentAssignment.id,
                },
            });
            if (admin) {
                if (state !== WorkplanState.ENDORSED) goToNextVersion();
                else window.location.reload();
            } else navigate('/supervisor');
        } catch (e) {
            // handled by hook
        }
    };

    return (
        <WorkplanVersionStateDisplay
            render={([workplan, workplanVersion]) => {
                const isWorkplanComplete = workplan.state === WorkplanState.ENDORSED;

                return (
                    <>
                        <Dialog
                            {...dialogProps}
                            isLoading={isSubmittingWorkplan}
                            errorMsg={
                                submittingError
                                    ? 'Could not update Workplan status, refresh the page to try again, or return to Dashboard.'
                                    : ''
                            }
                            onClose={() => {
                                setDialogType(undefined);
                                setNextStateStage(undefined);
                            }}
                        ></Dialog>
                        <GridItem colSpan={2}>
                            <Flex direction="row" w={'full'} justifyContent="flex-end">
                                <VStack bg={'white'} p={6}>
                                    <HStack w="full" justifyContent="flex-end">
                                        <Stack direction="row" alignItems="center">
                                            {!isWorkplanComplete && (
                                                <>
                                                    {workplanVersion.state !== WorkplanState.MODERATION && (
                                                        <Button
                                                            colorScheme={'red'}
                                                            isLoading={isSubmittingWorkplan}
                                                            isDisabled={
                                                                workplan.state === WorkplanState.INFORMATION_REQUESTED
                                                            }
                                                            onClick={() =>
                                                                setDialogType(DialogType.REQUEST_INFORMATION)
                                                            }
                                                        >
                                                            Request for Information
                                                        </Button>
                                                    )}
                                                    <Button
                                                        colorScheme={'blue'}
                                                        isLoading={isSubmittingWorkplan}
                                                        isDisabled={
                                                            workplan.state === WorkplanState.INFORMATION_REQUESTED
                                                        }
                                                        onClick={() => setDialogType(DialogType.APPROVE)}
                                                    >
                                                        Approve Workplan
                                                    </Button>
                                                </>
                                            )}
                                            {admin && workplan.state === WorkplanState.ENDORSED && (
                                                <Button
                                                    colorScheme={'red'}
                                                    isLoading={isSubmittingWorkplan}
                                                    disabled={!allEOYRatingGiven}
                                                    onClick={() => setDialogType(DialogType.UNENDORSE)}
                                                >
                                                    Unendorse Workplan
                                                </Button>
                                            )}
                                        </Stack>
                                    </HStack>
                                </VStack>
                            </Flex>
                        </GridItem>
                    </>
                );
            }}
        />
    );
};

export default SupervisorActionButtonTile;
