import { LoadingSpinner } from '@/components';
import { useAssignment } from '@/components/contexts/AssignmentContext/useAssignment';
import { getUserListFromParam } from '@/components/pages/Admin/UserSupervision/urlUserListHelpers';
import SupervisingTable from '@/components/pages/Admin/UserSupervision/UserSupervisionTables/SupervisingTable';
import WorkplansTable from '@/components/pages/Admin/UserSupervision/UserSupervisionTables/WorkplansTable';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import { Assignment } from '@/wpt-lib/interfaces/Assignment';
import { useAsyncLayoutEffect } from '@/utils/hooks/useAsyncEffect';
import usePageTitle from '@/utils/hooks/usePageTitle';
import { useGetRequest } from '@/utils/useStatefulRequests';
import { Flex, Grid } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AdminHeader } from './HeaderTile/AdminHeader';
import { UserProvisionDetailsProfile } from './UserSupervisionDetailsProfile';
import AssignmentStatusEditor from '@/components/pages/Admin/UserStatus/AssignmentStatusEditor';
import SupervisorEditor from '@/components/pages/Admin/Supervisor/SupervisorEditor';
import WorkloadEditor from '@/components/pages/Admin/Workload/WorkloadEditor';
import WorkplanStatusEditor from '@/components/pages/Admin/WorkplanStatusEditor';

const UserSupervisionDetails = () => {
    usePageTitle('User Supervision Details');
    const { users, assignmentId } = useParams<{
        users: string;
        assignmentId: string;
    }>();
    const { currentAssignment } = useAssignment();
    const allUsers = useMemo(() => (users ? getUserListFromParam(users) : []), [users]);

    useAsyncLayoutEffect(async () => {
        try {
            await getAssignment();
        } catch (error) {
            // handled by hook
        }
    }, [currentAssignment, assignmentId]);

    const {
        makeRequest: makeAssignmentGetRequest,
        loading: assignmentLoading,
        responseData: assignment,
        error: assignmentError,
    } = useGetRequest<Assignment>();

    const getAssignment = async () => {
        if (currentAssignment) {
            await makeAssignmentGetRequest(`/assignment/${assignmentId}`, {
                params: {
                    assignment: currentAssignment.id,
                },
            });
        }
    };

    if (assignmentError) {
        return (
            <Flex justifyContent="center">
                <SimpleAlert title="Error" variant="subtle">
                    {`Could not load user supervision details`}
                </SimpleAlert>
            </Flex>
        );
    }

    return (
        <Grid
            w={'100%'}
            templateColumns={{
                sm: 'repeat(1, 1fr)',
                lg: 'repeat(1, 1fr)',
            }}
            gap={6}
            p={6}
        >
            <AdminHeader assignment={assignment} loading={assignmentLoading} />
            {assignmentLoading ? (
                <LoadingSpinner fullScreen={false} />
            ) : (
                assignment && <UserProvisionDetailsProfile assignment={assignment} />
            )}
            {assignment && currentAssignment && (
                <>
                    <WorkloadEditor assignment={assignment} currentAssignment={currentAssignment} />
                    <SupervisorEditor assignmentId={assignment.id} inactive={assignment.inactive} />
                    <AssignmentStatusEditor
                        assignment={assignment}
                        currentAssignment={currentAssignment}
                        refresh={getAssignment}
                    />
                    <WorkplanStatusEditor
                        assignment={assignment}
                        currentAssignment={currentAssignment}
                        refresh={getAssignment}
                    />
                </>
            )}
            <WorkplansTable assignment={assignment} assignmentId={assignmentId} />
            <SupervisingTable assignment={assignment} assignmentId={assignmentId} allUsers={allUsers} />
        </Grid>
    );
};
export default UserSupervisionDetails;
