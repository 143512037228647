import { Box, Checkbox, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { SimpleAlert } from '@/components/SimpleAlert/SimpleAlert';
import React from 'react';
import { usePutRequest } from '@/utils/useStatefulRequests';
import { UpdateDeclarationsDto } from '@/wpt-lib/interfaces/Workplan';
import { useAssignment } from '@/components/contexts/AssignmentContext/useAssignment';
import useWorkplanVersion from '@/components/contexts/VersionContext/useWorkplanVersion';

export const TrainingTileAdditions = ({ isReadonly }: { isReadonly: boolean }) => {
    const { currentAssignment } = useAssignment();
    const { workplanVersion, updateVersion } = useWorkplanVersion();

    const { error: saveError, makeRequest: save } = usePutRequest<unknown, UpdateDeclarationsDto>();

    const handleMandatoryTrainingDeclaration = async (mandatoryTrainingDeclaration: boolean) => {
        updateVersion({ mandatoryTrainingDeclaration });
        await save(
            `/workplan/${workplanVersion?.workplan.id}`,
            {
                declarations: { mandatoryTrainingDeclaration },
            },
            {
                params: {
                    assignment: currentAssignment?.id,
                },
            },
        );
    };

    return (
        <Box px={4} mb={4}>
            <>
                <Heading as="h3" size="sm" color="blue.500" marginBottom={2}>
                    Mandatory Training
                </Heading>
                <Flex flexDirection="column" gap={3} mb={5}>
                    <Text fontStyle="italic">
                        Compliance with mandatory training is an essential responsibility for all UTS Staff.
                    </Text>
                    <Text fontStyle="italic">
                        To identify mandatory training related to your role, refer to the list located on{' '}
                        <Link
                            href="https://studentutsedu.sharepoint.com/sites/Onboarding-portal/SitePages/Compl.aspx"
                            target="_blank"
                        >
                            Staff Connect
                        </Link>
                        .
                    </Text>
                    <Text fontStyle="italic">
                        For new staff, mandatory training should be undertaken within the first week (7 days) of
                        employment.
                    </Text>
                </Flex>
                <Checkbox
                    aria-label="Mandatory Training"
                    isChecked={!!workplanVersion?.mandatoryTrainingDeclaration}
                    onChange={(ev) => !isReadonly && handleMandatoryTrainingDeclaration(ev.target.checked)}
                >
                    I have completed all required mandatory training for this year. Please only tick this option when
                    training is complete during the workplan year.
                </Checkbox>
                {saveError && (
                    <SimpleAlert marginTop={1} variant="subtle">
                        <Text>Could not update the declarations, Please try again later</Text>
                    </SimpleAlert>
                )}
            </>
        </Box>
    );
};
