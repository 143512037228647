import { Assignment } from '@/wpt-lib/interfaces/Assignment';

export enum AuditLogChange {
    ADMIN_ADDED = 'ADMIN_ADDED',
    ADMIN_REMOVED = 'ADMIN_REMOVED',
    SUPERVISOR_ADDED = 'SUPERVISOR_ADDED',
    SUPERVISOR_REMOVED = 'SUPERVISOR_REMOVED',
    HOLIDAY_ADDED = 'HOLIDAY_ADDED',
    HOLIDAY_REMOVED = 'HOLIDAY_REMOVED',
    TRIM_UPLOADS = 'TRIM_UPLOADS',
    WORKLOAD_ALLOCATION_UPDATED = 'WORKLOAD_ALLOCATION_UPDATED',
    WORKPLAN_CHANGED = 'WORKPLAN_CHANGED',
    DEACTIVATED_ASSIGNMENT = 'DEACTIVATED_ASSIGNMENT',
    ACTIVATED_ASSIGNMENT = 'ACTIVATED_ASSIGNMENT',
}

export const mapAuditLogChangeTypeToString: Record<AuditLogChange, Record<string, string>> = {
    ADMIN_ADDED: { value: 'Administrator Added', color: 'green.600' },
    ADMIN_REMOVED: { value: 'Administrator Removed', color: 'red.600' },
    SUPERVISOR_ADDED: { value: 'Supervisor Added', color: 'green.600' },
    SUPERVISOR_REMOVED: { value: 'Supervisor Removed', color: 'red.600' },
    HOLIDAY_ADDED: { value: 'Holiday Date Added', color: 'teal.500' },
    HOLIDAY_REMOVED: { value: 'Holiday Date Removed', color: 'orange.500' },
    TRIM_UPLOADS: { value: 'Trim Upload', color: 'purple.500' },
    WORKLOAD_ALLOCATION_UPDATED: { value: 'Workload Allocation Updated', color: 'pink.500' },
    WORKPLAN_CHANGED: { value: 'Workplan Changed', color: 'blue.500' },
    DEACTIVATED_ASSIGNMENT: { value: 'Deactivated Assignment', color: 'red.500' },
    ACTIVATED_ASSIGNMENT: { value: 'Activated Assignment', color: 'green.600' },
};

export enum UserRole {
    ACADEMIC = 'Academic',
    GLOBAL_ADMIN = 'Global Admin',
    FACULTY_ADMIN = 'Faculty Admin',
    ORG_ADMIN = 'Org Admin',
    SUPERVISOR = 'Supervisor',
    SYSTEM = 'System',
}

export const mapUserRoleToString: Record<UserRole, string> = {
    'Faculty Admin': 'Faculty Administrator',
    'Global Admin': 'University Administrator',
    'Org Admin': 'Local Administrator',
    Supervisor: 'Supervisor',
    System: 'System',
    Academic: 'Academic',
};

export interface AuditLog {
    id: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    changedBy: Assignment;
    change: AuditLogChange;
    message: string;
    userRole: UserRole;
}
