import { Radio, FormControl, SimpleGrid, GridItem, FormLabel, RadioGroup, Stack, Text, Spacer } from '@chakra-ui/react';
import React from 'react';

export interface SingleRatingProps<DataType> {
    rating: string;
    title: string;
    value: DataType;
    readOnly?: boolean;
    handleChange(type: string, value: DataType): void;
}

const renderEOYRating = (value: number) => {
    switch (value) {
        case 1:
            return 'Below';
        case 2:
            return 'Partially Met';
        case 3:
            return 'Met';
        case 4:
            return 'Exceeding';
        case 5:
            return 'Highly Exceeding';
        case -1:
            return 'Not Applicable';
        default:
            return 'Please select a value';
    }
};

export const SingleEOYRating = ({
    readOnly,
    rating,
    title,
    handleChange,
    value,
    hasHighExceeding,
}: SingleRatingProps<number> & { hasHighExceeding: boolean }) => {
    const id = `${rating}-input`;
    const isOverallRating = rating === 'overallEndOfYearRating';

    return (
        <FormControl as="fieldset" p={4} width="100%">
            <SimpleGrid templateColumns={`1fr minmax(360px, max-content) 1fr`} gap={5} alignItems="center">
                <GridItem justifyContent="center">
                    <FormLabel as="legend" textAlign="right" m={0} htmlFor={id}>
                        {title}
                    </FormLabel>
                </GridItem>
                <GridItem textAlign="center">
                    <RadioGroup
                        id={id}
                        onChange={(value) => handleChange(rating, parseInt(value))}
                        value={value ?? undefined}
                    >
                        <Stack direction="row" spacing={6} justifyContent={'flex-end'} alignItems={'center'}>
                            {!isOverallRating ? (
                                <Radio isReadOnly={readOnly} value={-1} size={'lg'} cursor={'pointer'}>
                                    NA
                                </Radio>
                            ) : (
                                <Spacer width="50px" />
                            )}

                            {Array(hasHighExceeding ? 5 : 4)
                                .fill(0)
                                .map((_, value) => (
                                    <Radio
                                        isReadOnly={readOnly}
                                        key={value}
                                        value={value + 1}
                                        size={'lg'}
                                        cursor={'pointer'}
                                    >
                                        {renderEOYRating(value + 1)}
                                    </Radio>
                                ))}
                        </Stack>
                    </RadioGroup>
                </GridItem>
                <GridItem textAlign="left">
                    <Text fontSize={'md'} color="blue.550" fontWeight={'bold'}>
                        {renderEOYRating(value)}
                    </Text>
                </GridItem>
            </SimpleGrid>
        </FormControl>
    );
};
